import styled from "styled-components";

import { Link } from "react-router-dom";

import { ICurrentAdmin } from "../../../helpers/interfaces";

interface IProps {
  currentAdmin: ICurrentAdmin;
}

const Navigation = (props: IProps) => (
  <Container>
    <StyledLink to="/">Properties</StyledLink>

    {props.currentAdmin.isSuperAdmin === 1 && (
      <StyledLink to="/admins">Admins</StyledLink>
    )}
  </Container>
);

export default Navigation;

const Container = styled.div`
  display: flex;

  flex-direction: column;

  gap: 0.5rem;
`;

const StyledLink = styled(Link)`
  padding: 0.5rem;

  text-decoration: none;

  &:hover {
    background-color: #6b6b6b;

    color: #ffffff;
  }
`;
