import { useState } from "react";

import styled from "styled-components";

import ButtonControl from "../../controls/Button";

import TextInputControl from "../../controls/TextInput";

import apiAxios from "../../../../helpers/apiAxios";

import { AxiosError } from "axios";

import { RouteComponentProps } from "react-router";

import { IParameters } from "../../../../helpers/interfaces";

import { ICurrentAdmin } from "../../../../helpers/interfaces";

import { Link } from "react-router-dom";

interface IMatchParams {
  adminId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  parameters: IParameters;

  sessionId: string;

  currentAdmin: ICurrentAdmin;

  onSessionInvalid: () => void;
}

const ChangePassword = (props: IProps) => {
  const [password, setPassword] = useState<string | null>(null);

  const onSave = () => {
    if (!password)
      return alert(
        "Incomplete form; completed all required form fields and try again."
      );

    if (
      !window.confirm(
        `You are about to change the password for this Admin.\n\nProceed?`
      )
    )
      return;

    const formData = new FormData();

    formData.append("password", password);

    props.setProcessing(true);

    apiAxios

      .post(
        `/admin/secure/admins/${props.match.params.adminId}/change-password`,

        formData,

        {
          headers: {
            sessionId: props.sessionId,
          },
        }
      )

      .then(
        () => {
          props.setProcessing(false);

          props.history.push(
            `/admins/${props.match.params.adminId}/password-changed`
          );
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <PageTitle>Change Admin Password</PageTitle>

      <Subnavigation>
        <StyledLink to={`/admins/${props.match.params.adminId}`}>
          Back to Admin
        </StyledLink>
      </Subnavigation>

      <Content>
        <Sections>
          <Section>
            <SectionTitle>Details</SectionTitle>

            <SectionDescription>
              Specify the new password for this Admin by completing the form
              below.
            </SectionDescription>

            <SectionContent>
              <DetailsForm>
                <DetailsFormFields>
                  <DetailsFormField>
                    <DetailsFormFieldTitle>Password</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="text"
                        maxLength={50}
                        placeholder="Password"
                        value={password || ""}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>
                </DetailsFormFields>
              </DetailsForm>
            </SectionContent>
          </Section>
        </Sections>
      </Content>

      <Actions>
        <ButtonControl
          onClick={onSave}
          disabled={props.processing || !password}
        >
          Save
        </ButtonControl>
      </Actions>
    </Container>
  );
};

export default ChangePassword;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto auto 1fr auto;

  height: 100%;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  padding-bottom: 0.5rem;

  border-bottom: solid 1px #6b6b6b;

  margin-bottom: 0.5rem;
`;

const Subnavigation = styled.div`
  display: flex;

  align-items: center;

  gap: 0.5rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.3rem;

  text-decoration: none;

  padding: 0.3rem;

  &:hover {
    background-color: #6b6b6b;

    color: #ffffff;
  }
`;

const Content = styled.div``;

const Sections = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const Section = styled.div`
  border: solid 1px #f6f6f6;

  padding: 0.5rem;

  display: grid;

  grid-template-rows: auto auto 1fr;

  grid-gap: 0.5rem;

  height: 100%;
`;

const SectionTitle = styled.div`
  font-size: 1.5rem;

  font-weight: 600;

  text-transform: uppercase;
`;

const SectionDescription = styled.div`
  font-size: 1.3rem;
`;

const SectionContent = styled.div`
  background-color: #f6f6f6;

  padding: 0.5rem;

  height: 100%;

  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsForm = styled.div``;

const DetailsFormFields = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormField = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormFieldTitle = styled.div``;

const DetailsFormFieldInput = styled.div`
  display: grid;
`;

const Actions = styled.div`
  display: flex;

  justify-content: flex-end;

  gap: 0.5rem;
`;
