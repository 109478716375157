import styled from "styled-components";

import { Switch, Route } from "react-router-dom";

import { ICurrentAdmin, IParameters } from "../../helpers/interfaces";

import CreateAdminPage from "./pages/admins/Create";

import AdminIndexPage from "./pages/admins/Index";

import AdminCreatedPage from "./pages/admins/Created";

import UpdateAdminPage from "./pages/admins/Update";

import AdminUpdatedPage from "./pages/admins/Updated";

import AdminDeletedPage from "./pages/admins/Deleted";

import ChangeAdminPasswordPage from "./pages/admins/ChangePassword";

import AdminPasswordChangedPage from "./pages/admins/PasswordChanged";

import CreatePropertyPage from "./pages/properties/Create";

import PropertyCreatedPage from "./pages/properties/Created";

import UpdatePropertyPage from "./pages/properties/Update";

import PropertyUpdatedPage from "./pages/properties/Updated";

import PropertyDeletedPage from "./pages/properties/Deleted";

import PropertyBlastedPage from "./pages/properties/Blasted";

import PropertySentPage from "./pages/properties/Sent";

import PropertyIndexPage from "./pages/properties/Index";

import PageNotFoundPage from "./pages/PageNotFound";

import UnauthorizedPage from "./pages/Unauthorized";

import NotAllowedPage from "./pages/NotAllowed";

import HeaderSection from "./sections/Header";

import NavigationSection from "./sections/Navigation";

import FooterSection from "./sections/Footer";

interface IProps {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  parameters: IParameters;

  sessionId: string;

  currentAdmin: ICurrentAdmin;

  onLogoutComplete: () => void;

  onSessionInvalid: () => void;
}

const Ui = (props: IProps) => (
  <Container>
    <HeaderSection
      processing={props.processing}
      setProcessing={props.setProcessing}
      sessionId={props.sessionId}
      currentAdmin={props.currentAdmin}
      onLogoutComplete={props.onLogoutComplete}
      onSessionInvalid={props.onSessionInvalid}
    />

    <Content>
      <NavigationSection currentAdmin={props.currentAdmin} />

      {props.currentAdmin.isSuperAdmin === 1 ? (
        <Switch>
          <Route
            exact
            path="/admins"
            render={(switchProps) => (
              <AdminIndexPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                sessionId={props.sessionId}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/admins/create"
            render={(switchProps) => (
              <CreateAdminPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                parameters={props.parameters}
                sessionId={props.sessionId}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/admins/created"
            render={() => <AdminCreatedPage />}
          />

          <Route
            exact
            path="/admins/deleted"
            render={() => <AdminDeletedPage />}
          />

          <Route
            exact
            path="/admins/:adminId"
            render={(switchProps) => (
              <UpdateAdminPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                parameters={props.parameters}
                sessionId={props.sessionId}
                currentAdmin={props.currentAdmin}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/admins/:adminId/updated"
            render={(switchProps) => <AdminUpdatedPage {...switchProps} />}
          />

          <Route
            exact
            path="/admins/:adminId/change-password"
            render={(switchProps) => (
              <ChangeAdminPasswordPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                parameters={props.parameters}
                sessionId={props.sessionId}
                currentAdmin={props.currentAdmin}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/admins/:adminId/password-changed"
            render={(switchProps) => (
              <AdminPasswordChangedPage {...switchProps} />
            )}
          />

          <Route
            exact
            path="/properties"
            render={(switchProps) => (
              <PropertyIndexPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                sessionId={props.sessionId}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/properties/create"
            render={(switchProps) => (
              <CreatePropertyPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                parameters={props.parameters}
                sessionId={props.sessionId}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/properties/created"
            render={() => <PropertyCreatedPage />}
          />

          <Route
            exact
            path="/properties/deleted"
            render={() => <PropertyDeletedPage />}
          />

          <Route
            exact
            path="/properties/:propertyId"
            render={(switchProps) => (
              <UpdatePropertyPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                parameters={props.parameters}
                sessionId={props.sessionId}
                currentAdmin={props.currentAdmin}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/properties/:propertyId/updated"
            render={(switchProps) => <PropertyUpdatedPage {...switchProps} />}
          />

          <Route
            exact
            path="/properties/:propertyId/blasted"
            render={(switchProps) => <PropertyBlastedPage {...switchProps} />}
          />

          <Route
            exact
            path="/properties/:propertyId/sent"
            render={(switchProps) => <PropertySentPage {...switchProps} />}
          />

          <Route
            exact
            path="/unauthorized"
            render={() => <UnauthorizedPage />}
          />

          <Route exact path="/not-allowed" render={() => <NotAllowedPage />} />

          <Route
            exact
            path="/"
            render={(switchProps) => (
              <PropertyIndexPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                sessionId={props.sessionId}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route exact render={() => <PageNotFoundPage />} />
        </Switch>
      ) : (
        <Switch>
          <Route
            exact
            path="/properties"
            render={(switchProps) => (
              <PropertyIndexPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                sessionId={props.sessionId}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/properties/create"
            render={(switchProps) => (
              <CreatePropertyPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                parameters={props.parameters}
                sessionId={props.sessionId}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/properties/created"
            render={() => <PropertyCreatedPage />}
          />

          <Route
            exact
            path="/properties/:propertyId"
            render={(switchProps) => (
              <UpdatePropertyPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                parameters={props.parameters}
                sessionId={props.sessionId}
                currentAdmin={props.currentAdmin}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route
            exact
            path="/properties/:propertyId/updated"
            render={(switchProps) => <PropertyUpdatedPage {...switchProps} />}
          />

          <Route
            exact
            path="/unauthorized"
            render={() => <UnauthorizedPage />}
          />

          <Route exact path="/not-allowed" render={() => <NotAllowedPage />} />

          <Route
            exact
            path="/"
            render={(switchProps) => (
              <PropertyIndexPage
                {...switchProps}
                processing={props.processing}
                setProcessing={props.setProcessing}
                sessionId={props.sessionId}
                onSessionInvalid={props.onSessionInvalid}
              />
            )}
          />

          <Route exact render={() => <PageNotFoundPage />} />
        </Switch>
      )}
    </Content>

    <FooterSection />
  </Container>
);

export default Ui;

const Container = styled.div`
  display: grid;

  grid-template-rows: auto 1fr auto;

  grid-gap: 1rem;

  height: 100%;

  padding: 2rem;
`;

const Content = styled.div`
  display: grid;

  grid-template-columns: 20rem 1fr;

  grid-gap: 1rem;
`;
