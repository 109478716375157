import { createGlobalStyle } from "styled-components";

import QuicksandWoff2 from "./assets/quicksand-font.woff2";

const GlobalStyle = createGlobalStyle`







  @font-face {







    font-family: 'Quicksand';







    font-style: normal;







    font-weight: 400;







    font-display: swap;







    src: url(${QuicksandWoff2}) format('woff2');







    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;







  }







  * {







    box-sizing: border-box;







    -webkit-font-smoothing: antialiased;







    font-family: "Quicksand", sans-serif;







    color: #6b6b6b;







    font-size: 18px;







    user-select:none;







    -ms-user-select:none;







    -moz-user-select:none;







    -webkit-user-select: none;







    /* border: solid 1px red; */







  }







  img, a {







    display: block;







  }







  html {







    margin: 0;







    padding: 0;







    height: 100%;







    > body {







      margin: 0;







      padding: 0;







      height: 100%;







      > #root {







        margin: 0;







        padding: 0;







        height: 100%;







        overflow-y: scroll;







      }







    }







  }







`;

export default GlobalStyle;
