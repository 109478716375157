import styled, { css } from "styled-components";

import ButtonControl from "../controls/Button";

import { formatBytes } from "../../../helpers/functions";

import { IParameters } from "../../../helpers/interfaces";

interface IProps {
  parameters: IParameters;

  photoFileSrc: string;

  photoFileSizeBytes: number;

  index: number;

  totalPhotos: number;

  onMoveDown: (index: number) => void;

  onMoveUp: (index: number) => void;

  onRemove: (index: number) => void;
}

const PhotoTile = (props: IProps) => {
  const photoOk =
    props.photoFileSizeBytes <= props.parameters.maxPhotoFileSizeBytes;

  return (
    <Container photoOk={photoOk}>
      <PhotoImageContainer>
        <PhotoImage src={props.photoFileSrc} alt=" photo" />

        <PhotoPosition>
          {props.index === 0 ? (
            "Photo #1 (Primary)"
          ) : (
            <>Photo #{props.index + 1}</>
          )}
        </PhotoPosition>

        <PhotoFileSize>{formatBytes(props.photoFileSizeBytes)}</PhotoFileSize>
      </PhotoImageContainer>

      <PhotoActions>
        <ButtonControl
          onClick={() => props.onMoveDown(props.index)}
          disabled={props.index === props.totalPhotos - 1}
        >
          Down
        </ButtonControl>

        <ButtonControl onClick={() => props.onRemove(props.index)}>
          Remove
        </ButtonControl>

        <ButtonControl
          onClick={() => props.onMoveUp(props.index)}
          disabled={props.index === 0}
        >
          Up
        </ButtonControl>
      </PhotoActions>
    </Container>
  );
};

export default PhotoTile;

const Container = styled.div<{ photoOk: boolean }>`
  display: grid;

  grid-template-rows: 1fr auto;

  grid-gap: 0.5rem;

  padding: 0.5rem;

  ${(props) =>
    props.photoOk
      ? css`
          border: solid 3px green;
        `
      : css`
          border: solid 3px red;
        `}
`;

const PhotoImageContainer = styled.div`
  position: relative;

  background-color: #ffffff;
`;

const PhotoImage = styled.img`
  width: 100%;

  padding: 0.1rem;
`;

const PhotoActions = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr 1fr;

  grid-gap: 0.5rem;
`;

const PhotoPosition = styled.div`
  position: absolute;

  top: 0.5rem;

  left: 0.5rem;

  background-color: rgba(0, 0, 0, 0.7);

  color: #ffffff;

  padding: 0.5rem;

  font-size: 0.9rem;

  text-transform: uppercase;
`;

const PhotoFileSize = styled.div`
  position: absolute;

  bottom: 0.5rem;

  right: 0.5rem;

  background-color: rgba(0, 0, 0, 0.7);

  color: #ffffff;

  padding: 0.5rem;

  font-size: 0.7rem;

  text-transform: uppercase;
`;
