import styled from "styled-components";

import { ICurrentAdmin } from "../../../helpers/interfaces";

import apiAxios from "../../../helpers/apiAxios";

import { AxiosError, AxiosResponse } from "axios";

import logoImageFile from "../../../assets/logo-long-image.png";

import { Link } from "react-router-dom";

interface IProps {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  sessionId: string;

  currentAdmin: ICurrentAdmin;

  onLogoutComplete: () => void;

  onSessionInvalid: () => void;
}

const Header = (props: IProps) => {
  const onLogout = () => {
    props.setProcessing(true);

    apiAxios

      .delete("/admin/secure/sessions", {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          props.setProcessing(false);

          props.onLogoutComplete();
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <Logo>
        <Link to="/">
          <LogoImage src={logoImageFile}></LogoImage>
        </Link>
      </Logo>

      <SessionControl>
        <CurrentUserName>
          You are logged in as {props.currentAdmin.name}
        </CurrentUserName>

        {props.currentAdmin.isSuperAdmin === 1 && (
          <CurrentUserSecurityLevel>SUPER ADMIN</CurrentUserSecurityLevel>
        )}

        <Actions>
          <button onClick={onLogout} disabled={props.processing}>
            Logout
          </button>
        </Actions>
      </SessionControl>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  display: flex;

  align-items: center;

  gap: 0.5rem;

  justify-content: space-between;
`;

const Logo = styled.div``;

const LogoImage = styled.img`
  max-width: 30rem;
`;

const SessionControl = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  justify-content: space-between;
`;

const CurrentUserName = styled.div`
  text-align: right;
`;

const CurrentUserSecurityLevel = styled.div`
  text-align: right;
`;

const Actions = styled.div`
  display: flex;

  justify-content: flex-end;
`;
