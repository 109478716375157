import styled from "styled-components";

import { ChangeEvent } from "react";

interface IProps {
  type: string;

  placeholder: string;

  value: string;

  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;

  readOnly?: boolean;

  disabled?: boolean;

  required?: boolean;

  maxLength?: number;

  min?: number;

  max?: number;

  step?: number;
}

const TextInput = (props: IProps) => (
  <StyledInput
    type={props.type}
    placeholder={props.placeholder}
    value={props.value}
    onChange={props.onChange}
    readOnly={props.readOnly}
    disabled={props.disabled}
    required={props.required}
    maxLength={props.maxLength}
    min={props.min}
    max={props.max}
    step={props.step}
  />
);

export default TextInput;

const StyledInput = styled.input<{
  disabled?: boolean;

  readOnly?: boolean;
}>`
  padding: 0.5rem;

  outline: none;

  cursor: ${(props) =>
    props.disabled || props.readOnly ? "not-allowed" : "pointer"};
`;
