import { ChangeEvent, PropsWithChildren } from "react";

import styled from "styled-components";

interface IProps {
  disabled?: boolean;

  onChange?: (value: ChangeEvent<HTMLSelectElement>) => void;

  value: string;
}

const Select = (props: PropsWithChildren<IProps>) => (
  <StyledSelect
    value={props.value}
    onChange={props.onChange}
    disabled={props.disabled}
  >
    {props.children}
  </StyledSelect>
);

export default Select;

const StyledSelect = styled.select<{
  disabled?: boolean;

  readOnly?: boolean;
}>`
  padding: 0.5rem;

  outline: none;

  cursor: ${(props) =>
    props.disabled || props.readOnly ? "not-allowed" : "pointer"};
`;
