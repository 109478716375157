import { useState } from "react";

import styled from "styled-components";

import ButtonControl from "../../controls/Button";

import TextInputControl from "../../controls/TextInput";

import TextAreaControl from "../../controls/TextArea";

import SelectControl from "../../controls/Select";

import CheckboxControl from "../../controls/Checkbox";

import PhotoDropZoneControl from "../../controls/PhotoDropZone";

import { IParameters, IPhotoFile } from "../../../../helpers/interfaces";

import PhotoTileComponent from "../../components/PhotoTile";

import { arrayMoveImmutable } from "array-move";

import apiAxios from "../../../../helpers/apiAxios";

import { AxiosError } from "axios";

import { RouteComponentProps } from "react-router";

import { formatBytes } from "../../../../helpers/functions";

interface IProps extends RouteComponentProps {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  parameters: IParameters;

  sessionId: string;

  onSessionInvalid: () => void;
}

const Create = (props: IProps) => {
  const [address, setAddress] = useState<string | null>(null);

  const [price, setPrice] = useState<number | null>(null);

  const [arv, setArv] = useState<number | null>(null);

  const [descriptive, setDescriptive] = useState<string | null>(null);

  const [status, setStatus] = useState<number | null>(null);

  const [visible, setVisible] = useState<boolean | null>(null);

  const [photoFiles, setPhotoFiles] = useState<IPhotoFile[] | null>(null);

  const attachPhotosFiles = (newPhotoFiles: IPhotoFile[]) => {
    newPhotoFiles.forEach(
      (photoFile) => (photoFile.previewUri = URL.createObjectURL(photoFile))
    );

    setPhotoFiles(
      photoFiles ? [...photoFiles, ...newPhotoFiles] : newPhotoFiles
    );
  };

  const onMoveDown = (index: number) => {
    if (!photoFiles) return;

    if (index === photoFiles.length - 1) return;

    setPhotoFiles(arrayMoveImmutable(photoFiles, index, index + 1));
  };

  const onMoveUp = (index: number) => {
    if (!photoFiles) return;

    if (index === 0) return;

    setPhotoFiles(arrayMoveImmutable(photoFiles, index, index - 1));
  };

  const onRemove = (index: number) => {
    if (!photoFiles) return;

    const newPhotoFiles = photoFiles.filter(
      (photoFile, photoFileIndex) => photoFileIndex !== index
    );

    setPhotoFiles(newPhotoFiles || null);
  };

  const onSave = () => {
    if (!address || !price || !arv || !descriptive || !photoFiles)
      return alert(
        "Incomplete form; completed all required form fields and try again."
      );

    if (
      !window.confirm(
        `You are about to create a new Property with ${
          photoFiles.length
        } photo/s @ ${formatBytes(
          photoFiles.reduce((acc, photoFile) => acc + photoFile.size, 0)
        )}.\n\nProceed?`
      )
    )
      return;

    const formData = new FormData();

    formData.append("address", address);

    formData.append("price", price.toString());

    formData.append("arv", arv.toString());

    formData.append("descriptive", descriptive);

    formData.append("status", status?.toString() || "0");

    formData.append("visible", visible ? "1" : "0");

    photoFiles.forEach((photoFile) =>
      formData.append("photoFile", photoFile, photoFile.name)
    );

    props.setProcessing(true);

    apiAxios

      .post("/admin/secure/properties", formData, {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        () => {
          props.setProcessing(false);

          props.history.push("/properties/created");
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  const photosOk = photoFiles
    ? photoFiles.filter(
        (photoFile) => photoFile.size > props.parameters.maxPhotoFileSizeBytes
      ).length === 0
    : false;

  return (
    <Container>
      <PageTitle>Create Property</PageTitle>

      <Content>
        <Sections>
          <Section>
            <SectionTitle>Details</SectionTitle>

            <SectionDescription>
              Specify the Property details by completing the form below.
            </SectionDescription>

            <SectionContent>
              <DetailsForm>
                <DetailsFormFields>
                  <DetailsFormField>
                    <DetailsFormFieldTitle>Address</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="text"
                        maxLength={50}
                        placeholder="Address"
                        value={address || ""}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Price</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="number"
                        min={0}
                        step={1}
                        placeholder="Price"
                        value={price?.toString() || ""}
                        onChange={(e) =>
                          setPrice(parseInt(e.target.value) || 0)
                        }
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>ARV</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="number"
                        min={0}
                        step={1}
                        placeholder="Price"
                        value={arv?.toString() || ""}
                        onChange={(e) => setArv(parseInt(e.target.value) || 0)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Status</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextAreaControl
                        disabled={props.processing}
                        required
                        maxLength={65535}
                        placeholder="Descriptive"
                        value={descriptive || ""}
                        onChange={(e) => setDescriptive(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Status</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <SelectControl
                        value={status?.toString() || "0"}
                        disabled={props.processing}
                        onChange={(e) => setStatus(parseInt(e.target.value))}
                      >
                        <option value="0">For Sale</option>
                        <option value="1">UCB</option>
                        <option value="2">Sold</option>
                      </SelectControl>
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Visibility</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <CheckboxControl
                        disabled={props.processing}
                        required
                        labelText="This Property should be shown on the site"
                        checked={visible || false}
                        onChange={(e) => setVisible(e.target.checked)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>
                </DetailsFormFields>
              </DetailsForm>
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle>Photo Gallery</SectionTitle>

            <SectionDescription>
              Attach at least one Photo to this Property (the file size limit
              per photo is {formatBytes(props.parameters.maxPhotoFileSizeBytes)}
              ).
            </SectionDescription>

            <SectionContent>
              <PhotoDropZoneControl
                disabled={props.processing}
                photoFilesDropped={attachPhotosFiles}
              />

              {photoFiles && (
                <PhotoGallery>
                  <Photos>
                    {photoFiles.map((photoFile, index) => (
                      <>
                        {photoFile.previewUri && (
                          <PhotoTileComponent
                            parameters={props.parameters}
                            onMoveDown={onMoveDown}
                            onMoveUp={onMoveUp}
                            onRemove={onRemove}
                            photoFileSrc={photoFile.previewUri}
                            photoFileSizeBytes={photoFile.size}
                            index={index}
                            totalPhotos={photoFiles.length}
                          />
                        )}
                      </>
                    ))}
                  </Photos>
                </PhotoGallery>
              )}
            </SectionContent>
          </Section>
        </Sections>
      </Content>

      <Actions>
        <ButtonControl
          onClick={onSave}
          disabled={
            props.processing ||
            !address ||
            !price ||
            !arv ||
            !descriptive ||
            !photoFiles ||
            !photosOk
          }
        >
          Save
        </ButtonControl>
      </Actions>
    </Container>
  );
};

export default Create;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto 1fr auto;

  height: 100%;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  padding-bottom: 0.5rem;

  border-bottom: solid 1px #6b6b6b;

  margin-bottom: 0.5rem;
`;

const Content = styled.div``;

const Sections = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const Section = styled.div`
  border: solid 1px #f6f6f6;

  padding: 0.5rem;

  display: grid;

  grid-template-rows: auto auto 1fr;

  grid-gap: 0.5rem;

  height: 100%;
`;

const SectionTitle = styled.div`
  font-size: 1.5rem;

  font-weight: 600;

  text-transform: uppercase;
`;

const SectionDescription = styled.div`
  font-size: 1.3rem;
`;

const SectionContent = styled.div`
  background-color: #f6f6f6;

  padding: 0.5rem;

  height: 100%;

  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsForm = styled.div``;

const DetailsFormFields = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormField = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormFieldTitle = styled.div``;

const DetailsFormFieldInput = styled.div`
  display: grid;
`;

const Actions = styled.div`
  display: flex;

  justify-content: flex-end;
`;

const PhotoGallery = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const Photos = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-columns: repeat(4, 1fr);
`;
