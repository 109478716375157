import styled from "styled-components";

import { IAdmin } from "../../../helpers/interfaces";

interface IProps {
  index: number;

  admin: IAdmin;
}

const AdminTile = (props: IProps) => (
  <Container>
    <Summary>
      <Name>{props.admin.name}</Name>

      <Details>
        Status: {props.admin.isSuperAdmin === 1 ? "SUPER ADMIN" : "NORMAL USER"}
      </Details>
    </Summary>
  </Container>
);

export default AdminTile;

const Container = styled.div`
  position: relative;

  height: 100%;

  box-shadow: 10px 10px 10px #c9c9c9;

  display: grid;

  grid-template-rows: auto 1fr;

  border: solid 1px #c9c9c9;

  cursor: pointer;

  &:hover {
    border: solid 1px #a9a9a9;

    box-shadow: 5px 5px 5px #c9c9c9;
  }

  &:active {
    border: solid 1px #c9c9c9;

    box-shadow: none;

    top: 3px;
  }
`;

const Summary = styled.div`
  background-color: #6b6b6b;

  padding: 0.5rem;

  display: grid;

  grid-gap: 0.5rem;
`;

const Name = styled.div`
  font-size: 1rem;

  font-weight: 600;

  color: #ffffff;

  white-space: nowrap;

  overflow: hidden;

  text-overflow: ellipsis;
`;

const Details = styled.div`
  font-size: 0.9rem;

  color: #ffffff;
`;
