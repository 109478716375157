import styled from "styled-components";

import { ChangeEvent } from "react";

interface IProps {
  placeholder: string;

  value: string;

  onChange: (value: ChangeEvent<HTMLTextAreaElement>) => void;

  disabled?: boolean;

  readOnly?: boolean;

  required?: boolean;

  maxLength?: number;
}

const TextArea = (props: IProps) => (
  <StyledTextArea
    placeholder={props.placeholder}
    value={props.value}
    onChange={props.onChange}
    disabled={props.disabled}
    readOnly={props.readOnly}
    required={props.required}
    maxLength={props.maxLength}
  />
);

export default TextArea;

const StyledTextArea = styled.textarea<{
  disabled?: boolean;

  readOnly?: boolean;
}>`
  resize: none;

  height: 5rem;

  padding: 0.5rem;

  outline: none;

  cursor: ${(props) =>
    props.disabled || props.readOnly ? "not-allowed" : "pointer"};
`;
