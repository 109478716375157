import { useState, useEffect, useCallback } from "react";

import { BrowserRouter } from "react-router-dom";

import styled, { css } from "styled-components";

import LoggedOutUi from "./uis/loggedOut/Ui";

import LoggedInUi from "./uis/loggedIn/Ui";

import apiAxios from "./helpers/apiAxios";

import { AxiosError, AxiosResponse } from "axios";

import { ICurrentAdmin, IParameters } from "./helpers/interfaces";

import loadingImageFile from "./assets/loading.gif";

const App = () => {
  const [processing, setProcessing] = useState(true);

  const [parameters, setParameters] = useState<IParameters | null>(null);

  const [sessionId, setSessionId] = useState<string | null>(null);

  const [currentAdmin, setCurrentAdmin] = useState<ICurrentAdmin | null>(null);

  const storageListener = () => {
    const storedSessionId = localStorage.getItem("sessionId");

    if (!storedSessionId) return setSessionId(null);
  };

  useEffect(() => {
    window.addEventListener("storage", storageListener);

    return () => window.removeEventListener("storage", storageListener);
  }, []);

  const onSessionInvalid = useCallback(() => {
    localStorage.removeItem("sessionId");

    setSessionId(null);

    alert("You have been logged out because your session is no longer valid.");
  }, []);

  useEffect(() => {
    apiAxios

      .get("/admin/parameters")

      .then(
        (axiosResponse: AxiosResponse) => {
          setParameters(axiosResponse.data.parameters);

          const storedSessionId = localStorage.getItem("sessionId");

          if (!storedSessionId) return setProcessing(false);

          apiAxios

            .get("/admin/secure/sessions", {
              headers: {
                sessionId: storedSessionId,
              },
            })

            .then(
              (axiosResponse: AxiosResponse) => {
                setSessionId(storedSessionId);

                setCurrentAdmin(axiosResponse.data.currentAdmin);

                setProcessing(false);
              },

              (axiosError: AxiosError) => {
                setProcessing(false);

                if (axiosError.response?.data?.errorCode === "accessDenied")
                  return onSessionInvalid();

                if (axiosError.response?.data.errorCode)
                  return alert(
                    `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
                  );

                alert(
                  `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
                );
              }
            );
        },

        (axiosError: AxiosError) => {
          setProcessing(false);

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  }, [onSessionInvalid]);

  const loginComplete = (sessionId: string, currentAdmin: ICurrentAdmin) => {
    localStorage.setItem("sessionId", sessionId);

    setSessionId(sessionId);

    setCurrentAdmin(currentAdmin);
  };

  const onLogoutComplete = () => {
    localStorage.removeItem("sessionId");

    setSessionId(null);
  };

  return (
    <>
      <Container processing={processing}>
        <BrowserRouter>
          {parameters && (
            <BrowserRouter>
              {sessionId && currentAdmin ? (
                <LoggedInUi
                  processing={processing}
                  setProcessing={setProcessing}
                  parameters={parameters}
                  sessionId={sessionId}
                  currentAdmin={currentAdmin}
                  onLogoutComplete={onLogoutComplete}
                  onSessionInvalid={onSessionInvalid}
                />
              ) : (
                <LoggedOutUi
                  processing={processing}
                  setProcessing={setProcessing}
                  loginComplete={loginComplete}
                />
              )}
            </BrowserRouter>
          )}
        </BrowserRouter>
      </Container>

      {processing && (
        <LoadingOverlay>
          <img src={loadingImageFile} alt="" />
        </LoadingOverlay>
      )}
    </>
  );
};

export default App;

const Container = styled.div<{ processing: boolean }>`
  height: 100%;

  ${(props) =>
    props.processing &&
    css`
      overflow: hidden;
    `}
`;

const LoadingOverlay = styled.div`
  position: fixed;

  top: 0;

  bottom: 0;

  right: 0;

  left: 0;

  display: flex;

  justify-content: center;

  align-items: center;

  background-color: #ffffff;
`;
