import { StrictMode } from "react";

import ReactDOM from "react-dom";

import App from "./App";

import * as serviceWorker from "./registerServiceWorker";

import GlobalStyle from "./GlobalStyle";

ReactDOM.render(
  <StrictMode>
    <GlobalStyle />

    <App />
  </StrictMode>,

  document.getElementById("root")
);

serviceWorker.unregister();
