import { useState, useEffect } from "react";

import styled from "styled-components";

import apiAxios from "../../../../helpers/apiAxios";

import { AxiosError, AxiosResponse } from "axios";

import { RouteComponentProps } from "react-router";

import { IProperty } from "../../../../helpers/interfaces";

import PropertyTileComponent from "../../components/PropertyTile";

import ButtonControl from "../../controls/Button";

import { Link } from "react-router-dom";

interface IProps extends RouteComponentProps {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  sessionId: string;

  onSessionInvalid: () => void;
}

const Index = (props: IProps) => {
  const [ready, setReady] = useState(false);

  const [properties, setProperties] = useState<IProperty[] | null>(null);

  const { setProcessing, history, onSessionInvalid, sessionId } = props;

  useEffect(() => {
    setProcessing(true);

    apiAxios

      .get("/admin/secure/properties", {
        headers: {
          sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          setProcessing(false);

          setReady(true);

          setProperties(axiosResponse.data.properties);
        },

        (axiosError: AxiosError) => {
          setProcessing(false);

          setReady(true);

          if (axiosError.response?.data?.errorCode === "propertiesNotFound")
            return;

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  }, [setProcessing, history, onSessionInvalid, sessionId]);

  const onRefresh = () => {
    props.setProcessing(true);

    apiAxios

      .get("/admin/secure/properties", {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          props.setProcessing(false);

          setReady(true);

          setProperties(axiosResponse.data.properties);
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          setReady(true);

          if (axiosError.response?.data?.errorCode === "propertiesNotFound")
            return;

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <PageTitle>Properties</PageTitle>

      <Subnavigation>
        <StyledLink to="/properties/create">Create</StyledLink>
      </Subnavigation>

      <Content>
        {ready && (
          <>
            {properties ? (
              <Properties>
                {properties.map((property, index) => (
                  <Property key={index} to={`/properties/${property.id}`}>
                    <PropertyTileComponent index={index} property={property} />
                  </Property>
                ))}
              </Properties>
            ) : (
              <NoPropertiesMessage>
                There are no Properties.
              </NoPropertiesMessage>
            )}
          </>
        )}
      </Content>

      <Actions>
        <ButtonControl onClick={onRefresh} disabled={props.processing}>
          Refresh
        </ButtonControl>
      </Actions>
    </Container>
  );
};

export default Index;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto auto 1fr auto;

  height: 100%;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  border-bottom: solid 1px #6b6b6b;
`;

const Subnavigation = styled.div`
  display: flex;

  align-items: center;

  gap: 0.5rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.3rem;

  text-decoration: none;

  padding: 0.3rem;

  &:hover {
    background-color: #6b6b6b;

    color: #ffffff;
  }
`;

const Content = styled.div``;

const NoPropertiesMessage = styled.div``;

const Properties = styled.div`
  position: relative;
  display: grid;

  grid-gap: 0.5rem;

  grid-template-columns: repeat(4, 0.25fr);
`;

const Property = styled(Link)`
  text-decoration: none;
`;

const Actions = styled.div`
  display: flex;

  justify-content: flex-end;
`;
