import styled from "styled-components";

const PageNotFound = () => (
  <Container>
    <PageTitle>Page Not Found</PageTitle>

    <Description>The page you requested was not found.</Description>
  </Container>
);

export default PageNotFound;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto 1fr auto;

  height: 100%;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  border-bottom: solid 1px #6b6b6b;
`;

const Description = styled.div`
  font-size: 1.3rem;
`;
