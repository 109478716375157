import styled from "styled-components";

import { IProperty } from "../../../helpers/interfaces";

import config from "../../../helpers/config";

import momentTimezone from "moment-timezone";

interface IProps {
  index: number;

  property: IProperty;
}

const PropertyTile = (props: IProps) => (
  <Container status={props.property.status} visible={props.property.visible}>
    <Summary status={props.property.status} visible={props.property.visible}>
      <Address status={props.property.status} visible={props.property.visible}>
        {props.property.address}
      </Address>

      <Details status={props.property.status} visible={props.property.visible}>
        Status:{" "}
        {props.property.status === 0
          ? "NOT SOLD"
          : props.property.status === 1
          ? "UCB"
          : props.property.status === 2
          ? "SOLD"
          : "UNKNOWN"}
        . Visibility:{" "}
        {props.property.visible === 0
          ? "HIDDEN"
          : props.property.visible === 1
          ? "SHOWN"
          : "UNKNOWN"}
      </Details>

      <LastBlasted
        status={props.property.status}
        visible={props.property.visible}
      >
        Last blasted:{" "}
        {props.property.lastBlasted
          ? momentTimezone.utc(props.property.lastBlasted).fromNow()
          : "NEVER"}
      </LastBlasted>
    </Summary>

    <PhotoImageContainer>
      <PhotoImage
        src={`${config.apiBaseUrl}/handlers/properties/${props.property.slug}/photos/primary`}
        alt={`Primary photo for ${props.property.address}`}
      />
    </PhotoImageContainer>
  </Container>
);

export default PropertyTile;

const Container = styled.div<{
  status: number;

  visible: number;
}>`
  position: relative;

  height: 100%;

  box-shadow: 10px 10px 10px #c9c9c9;

  display: grid;

  grid-template-rows: auto 1fr;

  ${({ status, visible }) =>
    visible === 0
      ? `
        border: solid 3px #c9c9c9;
      `
      : status === 0
      ? `
        border: solid 3px green;
      `
      : status === 1
      ? `
        border: solid 3px yellow;
      `
      : `
        border: solid 3px red;
      `}

  cursor: pointer;

  &:hover {
    border: solid 3px black;

    box-shadow: 5px 5px 5px #c9c9c9;
  }

  &:active {
    border: solid 1px #c9c9c9;

    box-shadow: none;

    top: 3px;
  }
`;

const PhotoImageContainer = styled.div`
  padding: 0.5rem;

  background-color: #ffffff;
`;

const PhotoImage = styled.img`
  width: 100%;

  padding: 0.1rem;
`;

const Summary = styled.div<{
  status: number;

  visible: number;
}>`
  background-color: #6b6b6b;

  ${({ status, visible }) =>
    visible === 0
      ? `
        background-color: #6b6b6b;
      `
      : status === 0
      ? `
        background-color: green;
      `
      : status === 1
      ? `
        background-color: yellow;
      `
      : `
        background-color: red;
      `}

  padding: 0.5rem;

  display: grid;

  grid-gap: 0.5rem;
`;

const Address = styled.div<{
  status: number;

  visible: number;
}>`
  font-size: 1rem;

  font-weight: 600;

  white-space: nowrap;

  overflow: hidden;

  text-overflow: ellipsis;

  ${({ status, visible }) =>
    visible === 0
      ? `
        color: white;
      `
      : status === 0
      ? `
        color: white;
      `
      : status === 1
      ? `
        color: black;
      `
      : `
        color: white;
      `}
`;

const Details = styled.div<{
  status: number;

  visible: number;
}>`
  font-size: 0.9rem;

  ${({ status, visible }) =>
    visible === 0
      ? `
        color: white;
      `
      : status === 0
      ? `
        color: white;
      `
      : status === 1
      ? `
        color: black;
      `
      : `
        color: white;
      `}
`;

const LastBlasted = styled.div<{
  status: number;

  visible: number;
}>`
  font-size: 0.9rem;

  ${({ status, visible }) =>
    visible === 0
      ? `
        color: white;
      `
      : status === 0
      ? `
        color: white;
      `
      : status === 1
      ? `
        color: black;
      `
      : `
        color: white;
      `}
`;
