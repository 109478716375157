import { useState, useEffect } from "react";

import styled from "styled-components";

import ButtonControl from "../../controls/Button";

import TextInputControl from "../../controls/TextInput";

import TextAreaControl from "../../controls/TextArea";

import CheckboxControl from "../../controls/Checkbox";

import apiAxios from "../../../../helpers/apiAxios";

import { AxiosError, AxiosResponse } from "axios";

import { RouteComponentProps } from "react-router";

import { IParameters } from "../../../../helpers/interfaces";

import { ICurrentAdmin, IProperty } from "../../../../helpers/interfaces";

import momentTimezone from "moment-timezone";

import SelectControl from "../../controls/Select";

import config from "../../../../helpers/config";

interface IMatchParams {
  propertyId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  parameters: IParameters;

  sessionId: string;

  currentAdmin: ICurrentAdmin;

  onSessionInvalid: () => void;
}

const Update = (props: IProps) => {
  const [address, setAddress] = useState<string | null>(null);

  const [propertyUrl, setPropertyUrl] = useState<string | null>(null);

  const [price, setPrice] = useState<number | null>(null);

  const [arv, setArv] = useState<number | null>(null);

  const [descriptive, setDescriptive] = useState<string | null>(null);

  const [status, setStatus] = useState<number | null>(null);

  const [visible, setVisible] = useState<boolean | null>(null);

  const [lastBlasted, setLastBlasted] = useState<string | null>(null);

  const nowMoment = momentTimezone.utc();

  const lastBlastedMoment = momentTimezone.utc(lastBlasted);

  const blastEnabled = lastBlasted
    ? nowMoment.diff(lastBlastedMoment, "days") >=
      props.parameters.daysBetweenBlasts
    : true;

  const { setProcessing, match, history, onSessionInvalid, sessionId } = props;

  useEffect(() => {
    setProcessing(true);

    apiAxios

      .get(`/admin/secure/properties/${match.params.propertyId}`, {
        headers: {
          sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          setProcessing(false);

          const property = axiosResponse.data.property as IProperty;

          setAddress(property.address);

          setPropertyUrl(
            `${config.websiteBaseUrl}/properties/${property.slug}`
          );

          setPrice(property.price);

          setArv(property.arv);

          setDescriptive(property.descriptive);

          setStatus(property.status);

          setVisible(property.visible === 1);

          setLastBlasted(property.lastBlasted);
        },

        (axiosError: AxiosError) => {
          setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  }, []);

  const onReset = () => {
    if (
      !window.confirm(
        `You are about to discard any changes made and reset the page.\n\nProceed?`
      )
    )
      return;

    props.setProcessing(true);

    apiAxios

      .get(`/admin/secure/properties/${props.match.params.propertyId}`, {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          props.setProcessing(false);

          const property = axiosResponse.data.property as IProperty;

          setAddress(property.address);

          setPrice(property.price);

          setArv(property.arv);

          setDescriptive(property.descriptive);

          setStatus(property.status);

          setVisible(property.visible === 1);

          setLastBlasted(property.lastBlasted);
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  const onDelete = () => {
    const confirmed = window.prompt(
      `You are about to delete this Property (including its Photos).\n\nIf you're sure, type DELETE and click OK to proceed.`
    );

    if (!confirmed) return;

    if (confirmed !== "DELETE")
      return alert(
        "Checkpoint failed; operation aborted.\n\nYou must enter DELETE (in capitals) to confirm the operation.\n\nPlease try again."
      );

    props.setProcessing(true);

    apiAxios

      .delete(`/admin/secure/properties/${props.match.params.propertyId}`, {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        () => {
          props.setProcessing(false);

          props.history.push(`/properties/deleted`);
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  const onBlast = () => {
    const confirmed = window.prompt(
      lastBlasted
        ? `You are about to blast this Property to the Amazing Flips mailing list.\n\nThis Property was last blasted ${lastBlastedMoment.fromNow()}.\n\nIf you're sure, type BLAST and click OK to proceed.`
        : `You are about to blast this Property to the Amazing Flips mailing list.\n\nIf you're sure, type BLAST and click OK to proceed.`
    );

    if (!confirmed) return;

    if (confirmed !== "BLAST")
      return alert(
        "Checkpoint failed; operation aborted.\n\nYou must enter BLAST (in capitals) to confirm the operation.\n\nPlease try again."
      );

    props.setProcessing(true);

    apiAxios

      .post(
        `/admin/secure/properties/${props.match.params.propertyId}/blast`,

        null,

        {
          headers: {
            sessionId: props.sessionId,
          },
        }
      )

      .then(
        () => {
          props.setProcessing(false);

          props.history.push(
            `/properties/${props.match.params.propertyId}/blasted`
          );
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "notAllowed")
            return props.history.push("/not-allowed");

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  const onSend = () => {
    const name = window.prompt("Enter recipient name.");

    const emailAddress = window.prompt("Enter recipient email address.");

    const confirmed = window.prompt(
      `You are about to send this Property to ${name}/${emailAddress}.\n\nIf you're sure, type SEND and click OK to proceed.`
    );

    if (!confirmed) return;

    if (confirmed !== "SEND")
      return alert(
        "Checkpoint failed; operation aborted.\n\nYou must enter SEND (in capitals) to confirm the operation.\n\nPlease try again."
      );

    props.setProcessing(true);

    apiAxios

      .post(
        `/admin/secure/properties/${props.match.params.propertyId}/send`,

        {
          name,

          emailAddress,
        },

        {
          headers: {
            sessionId: props.sessionId,
          },
        }
      )

      .then(
        () => {
          props.setProcessing(false);

          props.history.push(
            `/properties/${props.match.params.propertyId}/sent`
          );
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "notAllowed")
            return props.history.push("/not-allowed");

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  const onSave = () => {
    if (!address || !price || !arv || !descriptive)
      return alert(
        "Incomplete form; completed all required form fields and try again."
      );

    if (!window.confirm(`You are about to update this Property.\n\nProceed?`))
      return;

    const formData = new FormData();

    formData.append("address", address);

    formData.append("price", price.toString());

    formData.append("arv", arv.toString());

    formData.append("descriptive", descriptive);

    formData.append("status", status?.toString() || "0");

    formData.append("visible", visible ? "1" : "0");

    props.setProcessing(true);

    apiAxios

      .put(
        `/admin/secure/properties/${props.match.params.propertyId}`,

        formData,

        {
          headers: {
            sessionId: props.sessionId,
          },
        }
      )

      .then(
        () => {
          props.setProcessing(false);

          props.history.push(
            `/properties/${props.match.params.propertyId}/updated`
          );
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <PageTitle>Update Property</PageTitle>

      <Content>
        <Sections>
          <Section>
            <SectionTitle>Details</SectionTitle>

            <SectionDescription>
              Specify the Property details by completing the form below.
            </SectionDescription>

            <PropertyLink>
              🤜{"   "}
              <a href={propertyUrl!} target="_blank" rel="noreferrer">
                {propertyUrl}
              </a>
              {"   "}
              🤛
            </PropertyLink>

            {lastBlasted && (
              <SectionDescription>
                This Property was last blasted {lastBlastedMoment.fromNow()}.
                {!blastEnabled
                  ? ` You must wait a minimum of ${props.parameters.daysBetweenBlasts} day/s before blasting this Property again.`
                  : " You can blast this Property to the Amazing Flips mailing list."}
              </SectionDescription>
            )}

            <SectionContent>
              <DetailsForm>
                <DetailsFormFields>
                  <DetailsFormField>
                    <DetailsFormFieldTitle>Address</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="text"
                        maxLength={50}
                        placeholder="Address"
                        value={address || ""}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Price</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="number"
                        min={0}
                        step={1}
                        placeholder="Price"
                        value={price?.toString() || ""}
                        onChange={(e) =>
                          setPrice(parseInt(e.target.value) || 0)
                        }
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>ARV</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="number"
                        min={0}
                        step={1}
                        placeholder="Price"
                        value={arv?.toString() || ""}
                        onChange={(e) => setArv(parseInt(e.target.value) || 0)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Status</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextAreaControl
                        disabled={props.processing}
                        required
                        maxLength={65535}
                        placeholder="Descriptive"
                        value={descriptive || ""}
                        onChange={(e) => setDescriptive(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Status</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <SelectControl
                        value={status?.toString() || "0"}
                        disabled={props.processing}
                        onChange={(e) => setStatus(parseInt(e.target.value))}
                      >
                        <option value="0">For Sale</option>
                        <option value="1">UCB</option>
                        <option value="2">Sold</option>
                      </SelectControl>
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Visibility</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <CheckboxControl
                        disabled={props.processing}
                        required
                        labelText="This Property should be shown on the site"
                        checked={visible || false}
                        onChange={(e) => setVisible(e.target.checked)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>
                </DetailsFormFields>
              </DetailsForm>
            </SectionContent>
          </Section>
        </Sections>
      </Content>

      <Actions>
        {props.currentAdmin.isSuperAdmin === 1 && (
          <>
            <ButtonControl
              onClick={onSend}
              disabled={props.processing || !blastEnabled}
            >
              Send
            </ButtonControl>

            <ButtonControl
              onClick={onBlast}
              disabled={props.processing || !blastEnabled}
            >
              Blast
            </ButtonControl>

            <ButtonControl onClick={onDelete} disabled={props.processing}>
              Delete
            </ButtonControl>
          </>
        )}

        <ButtonControl onClick={onReset} disabled={props.processing}>
          Reset
        </ButtonControl>

        <ButtonControl
          onClick={onSave}
          disabled={
            props.processing || !address || !price || !arv || !descriptive
          }
        >
          Save
        </ButtonControl>
      </Actions>
    </Container>
  );
};

export default Update;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto 1fr auto;

  height: 100%;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  padding-bottom: 0.5rem;

  border-bottom: solid 1px #6b6b6b;

  margin-bottom: 0.5rem;
`;

const Content = styled.div``;

const Sections = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const Section = styled.div`
  border: solid 1px #f6f6f6;

  padding: 0.5rem;

  display: grid;

  grid-template-rows: auto auto 1fr;

  grid-gap: 0.5rem;

  height: 100%;
`;

const SectionTitle = styled.div`
  font-size: 1.5rem;

  font-weight: 600;

  text-transform: uppercase;
`;

const SectionDescription = styled.div`
  font-size: 1.3rem;
`;

const PropertyLink = styled.div`
  display: flex;
  font-size: 1.3rem;

  flex-direction: row;

  align-items: center;
`;

const SectionContent = styled.div`
  background-color: #f6f6f6;

  padding: 0.5rem;

  height: 100%;

  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsForm = styled.div``;

const DetailsFormFields = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormField = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormFieldTitle = styled.div``;

const DetailsFormFieldInput = styled.div`
  display: grid;
`;

const Actions = styled.div`
  display: flex;

  justify-content: flex-end;

  gap: 0.5rem;
`;
