import { useState, useEffect } from "react";

import styled from "styled-components";

import { useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { IPhotoFile } from "../../../helpers/interfaces";

interface IProps {
  disabled?: boolean;

  maxFiles?: number;

  photoFilesDropped: (acceptedFiles: IPhotoFile[]) => void;
}

const PhotoDropZone = (props: IProps) => {
  const { photoFilesDropped } = props;

  const [photoFiles, setPhotoFiles] = useState<IPhotoFile[] | null>(null);

  const onDrop = useCallback(
    (photoFiles: File[]) => {
      if (photoFiles.length === 0) return setPhotoFiles(null);

      setPhotoFiles(photoFiles);
    },

    []
  );

  useEffect(() => {
    if (!photoFiles) return;

    photoFilesDropped(photoFiles);
  }, [photoFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,

    maxFiles: props.maxFiles,

    accept: "image/jpeg",

    disabled: props.disabled,

    multiple: props.maxFiles !== 1,
  });

  return (
    <Container {...getRootProps()}>
      <StyledInput {...getInputProps()} />

      {isDragActive ? (
        <DropMessage>Drop files here...</DropMessage>
      ) : (
        <DropMessage>Drop files here or click to select files.</DropMessage>
      )}
    </Container>
  );
};

export default PhotoDropZone;

const Container = styled.div`
  cursor: pointer;
`;

const DropMessage = styled.div`
  padding: 1rem;

  background-color: #ffffff;
`;

const StyledInput = styled.input`
  outline: none;

  border: none;
`;
