import styled from "styled-components";

import { Link, RouteComponentProps } from "react-router-dom";

interface IMatchParams {
  propertyId: string;
}

const Blasted = (props: RouteComponentProps<IMatchParams>) => (
  <Container>
    <PageTitle>Property Blasted</PageTitle>

    <Description>The Property has been blasted successfully.</Description>

    <Content>
      <OptionLink>
        &#187;
        <StyledLink to={`/properties/${props.match.params.propertyId}`}>
          Back to Property
        </StyledLink>
      </OptionLink>
    </Content>
  </Container>
);

export default Blasted;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto auto 1fr;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  border-bottom: solid 1px #6b6b6b;
`;

const Content = styled.div`
  display: flex;

  flex-direction: column;

  gap: 0.5rem;
`;

const Description = styled.div`
  font-size: 1.3rem;
`;

const OptionLink = styled.div`
  display: flex;

  align-items: center;

  gap: 0.5rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.3rem;

  text-decoration: none;

  padding: 0.3rem;

  &:hover {
    background-color: #6b6b6b;

    color: #ffffff;
  }
`;
