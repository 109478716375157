import styled from "styled-components";

import { ChangeEvent } from "react";

interface IProps {
  labelText: string;

  checked: boolean;

  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;

  readOnly?: boolean;

  disabled?: boolean;

  required?: boolean;
}

const Checkbox = (props: IProps) => {
  const inputId = Math.random().toString();

  return (
    <Container>
      <StyledInput
        id={inputId}
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
        readOnly={props.readOnly}
        disabled={props.disabled}
        required={props.required}
      />

      <StyledLabel htmlFor={inputId}>{props.labelText}</StyledLabel>
    </Container>
  );
};

export default Checkbox;

const Container = styled.div`
  display: flex;

  gap: 0.5rem;

  align-items: center;
`;

const StyledInput = styled.input<{
  disabled?: boolean;

  readOnly?: boolean;
}>`
  padding: 0.5rem;

  outline: none;

  cursor: ${(props) =>
    props.disabled || props.readOnly ? "not-allowed" : "pointer"};
`;

const StyledLabel = styled.label<{
  disabled?: boolean;

  readOnly?: boolean;
}>`
  cursor: ${(props) =>
    props.disabled || props.readOnly ? "not-allowed" : "pointer"};
`;
