import { useState } from "react";

import styled from "styled-components";

import logoImageFile from "../../assets/logo-square-image.png";

import apiAxios from "../../helpers/apiAxios";

import { AxiosError, AxiosResponse } from "axios";

import { ICurrentAdmin } from "../../helpers/interfaces";

interface IProps {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  loginComplete: (sessionId: string, currentAdmin: ICurrentAdmin) => void;
}

const Ui = (props: IProps) => {
  const [emailAddress, setEmailAddress] = useState<string | null>(null);

  const [password, setPassword] = useState<string | null>(null);

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.setProcessing(true);

    apiAxios

      .post("/admin/insecure/sessions", {
        emailAddress,

        password,
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          props.setProcessing(false);

          setEmailAddress(null);

          setPassword(null);

          props.loginComplete(
            axiosResponse.data.sessionId,

            axiosResponse.data.currentAdmin
          );
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return alert(
              "Unable to log you in because those credentials are not valid, please check your entries and try again."
            );

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <Form onSubmit={handleLogin}>
        <LogoImageContainer>
          <LogoImage src={logoImageFile} />
        </LogoImageContainer>

        <Fields>
          <Field>
            <Prompt>Email Address</Prompt>

            <Input
              disabled={props.processing}
              type="email"
              maxLength={50}
              placeholder="Email Address"
              required
              value={emailAddress || ""}
              onChange={(e) => setEmailAddress(e.target.value || null)}
            />
          </Field>

          <Field>
            <Prompt>Password</Prompt>

            <Input
              disabled={props.processing}
              type="password"
              maxLength={50}
              required
              placeholder="Password"
              value={password || ""}
              onChange={(e) => setPassword(e.target.value || null)}
            />
          </Field>
        </Fields>

        <Actions>
          <Button disabled={props.processing}>Login</Button>
        </Actions>
      </Form>
    </Container>
  );
};

export default Ui;

const Container = styled.div`
  height: 100%;

  display: grid;

  align-items: center;

  padding: 1rem;

  justify-content: space-around;
`;

const Form = styled.form`
  border: solid 1px #f5f5f5;

  box-shadow: 10px 10px 10px #c9c9c9;

  padding: 2rem;

  display: grid;

  grid-gap: 1rem;

  max-width: 30rem;

  width: 100%;
`;

const LogoImageContainer = styled.div`
  display: grid;

  justify-content: center;
`;

const LogoImage = styled.img`
  width: 100%;
`;

const Fields = styled.div`
  display: grid;

  grid-gap: 1rem;
`;

const Field = styled.div`
  display: grid;

  grid-gap: 1rem;
`;

const Prompt = styled.div`
  text-align: left;

  font-size: 1.2rem;
`;

const Input = styled.input`
  cursor: pointer;

  font-size: 1.1rem;

  padding: 1rem;

  outline: 0;
`;

const Actions = styled.div`
  display: flex;

  justify-content: center;
`;

const Button = styled.button`
  font-size: 1.2rem;

  padding: 0.3rem 1rem;

  cursor: pointer;
`;
