import styled from "styled-components";

import { PropsWithChildren } from "react";

interface IProps {
  disabled?: boolean;

  onClick?: () => void;
}

const Button = (props: PropsWithChildren<IProps>) => (
  <StyledButton disabled={props.disabled} onClick={props.onClick}>
    {props.children}
  </StyledButton>
);

export default Button;

const StyledButton = styled.button<{
  disabled?: boolean;
}>`
  outline: none;

  border: solid 2px #0f693a;

  text-decoration: none;

  text-transform: uppercase;

  border-radius: 0.2rem;

  padding: 0.2rem 0.5rem;

  background-color: #0f693a;

  color: #ffffff;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #ffffff;

      color: #0f693a;
    }
  }

  &:active {
    background-color: rgba(38, 38, 38, 0.7);

    color: #ffffff;

    border: solid 2px transparent;
  }

  &:disabled {
    background-color: rgba(38, 38, 38, 0.7);

    color: #ffffff;

    border: solid 2px transparent;
  }
`;
