import { useState } from "react";

import styled from "styled-components";

import ButtonControl from "../../controls/Button";

import TextInputControl from "../../controls/TextInput";

import CheckboxControl from "../../controls/Checkbox";

import { IParameters } from "../../../../helpers/interfaces";

import apiAxios from "../../../../helpers/apiAxios";

import { AxiosError } from "axios";

import { RouteComponentProps } from "react-router";

interface IProps extends RouteComponentProps {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  parameters: IParameters;

  sessionId: string;

  onSessionInvalid: () => void;
}

const Create = (props: IProps) => {
  const [name, setName] = useState<string | null>(null);

  const [emailAddress, setEmailAddress] = useState<string | null>(null);

  const [password, setPassword] = useState<string | null>(null);

  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | null>(null);

  const onSave = () => {
    if (!name || !emailAddress || !password)
      return alert(
        "Incomplete form; completed all required form fields and try again."
      );

    if (!window.confirm(`You are about to create a new Admin.\n\nProceed?`))
      return;

    const formData = new FormData();

    formData.append("name", name);

    formData.append("emailAddress", emailAddress);

    formData.append("password", password);

    formData.append("isSuperAdmin", isSuperAdmin ? "1" : "0");

    props.setProcessing(true);

    apiAxios

      .post("/admin/secure/admins", formData, {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        () => {
          props.setProcessing(false);

          props.history.push("/admins/created");
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <PageTitle>Create Admin</PageTitle>

      <Content>
        <Sections>
          <Section>
            <SectionTitle>Details</SectionTitle>

            <SectionDescription>
              Specify the Admin details by completing the form below.
            </SectionDescription>

            <SectionContent>
              <DetailsForm>
                <DetailsFormFields>
                  <DetailsFormField>
                    <DetailsFormFieldTitle>Name</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="text"
                        maxLength={50}
                        placeholder="Name"
                        value={name || ""}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Email Address</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="email"
                        maxLength={50}
                        placeholder="Email Address"
                        value={emailAddress || ""}
                        onChange={(e) => setEmailAddress(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Password</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="text"
                        maxLength={50}
                        placeholder="Password"
                        value={password || ""}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>
                      Security Level
                    </DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <CheckboxControl
                        disabled={props.processing}
                        required
                        labelText="This Admin is a Super Admin"
                        checked={isSuperAdmin || false}
                        onChange={(e) => setIsSuperAdmin(e.target.checked)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>
                </DetailsFormFields>
              </DetailsForm>
            </SectionContent>
          </Section>
        </Sections>
      </Content>

      <Actions>
        <ButtonControl
          onClick={onSave}
          disabled={props.processing || !name || !emailAddress || !password}
        >
          Save
        </ButtonControl>
      </Actions>
    </Container>
  );
};

export default Create;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto 1fr auto;

  height: 100%;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  padding-bottom: 0.5rem;

  border-bottom: solid 1px #6b6b6b;

  margin-bottom: 0.5rem;
`;

const Content = styled.div``;

const Sections = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const Section = styled.div`
  border: solid 1px #f6f6f6;

  padding: 0.5rem;

  display: grid;

  grid-template-rows: auto auto 1fr;

  grid-gap: 0.5rem;

  height: 100%;
`;

const SectionTitle = styled.div`
  font-size: 1.5rem;

  font-weight: 600;

  text-transform: uppercase;
`;

const SectionDescription = styled.div`
  font-size: 1.3rem;
`;

const SectionContent = styled.div`
  background-color: #f6f6f6;

  padding: 0.5rem;

  height: 100%;

  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsForm = styled.div``;

const DetailsFormFields = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormField = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormFieldTitle = styled.div``;

const DetailsFormFieldInput = styled.div`
  display: grid;
`;

const Actions = styled.div`
  display: flex;

  justify-content: flex-end;
`;
