import { useState, useEffect } from "react";

import styled from "styled-components";

import apiAxios from "../../../../helpers/apiAxios";

import { AxiosError, AxiosResponse } from "axios";

import { RouteComponentProps } from "react-router";

import { IAdmin } from "../../../../helpers/interfaces";

import AdminTileComponent from "../../components/AdminTile";

import ButtonControl from "../../controls/Button";

import { Link } from "react-router-dom";

interface IProps extends RouteComponentProps {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  sessionId: string;

  onSessionInvalid: () => void;
}

const Index = (props: IProps) => {
  const [ready, setReady] = useState(false);

  const [admins, setAdmins] = useState<IAdmin[] | null>(null);

  const { setProcessing, history, onSessionInvalid, sessionId } = props;

  useEffect(() => {
    setProcessing(true);

    apiAxios

      .get("/admin/secure/admins", {
        headers: {
          sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          setProcessing(false);

          setReady(true);

          setAdmins(axiosResponse.data.admins);
        },

        (axiosError: AxiosError) => {
          setProcessing(false);

          setReady(true);

          if (axiosError.response?.data?.errorCode === "adminsNotFound") return;

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  }, [setProcessing, history, onSessionInvalid, sessionId]);

  const onRefresh = () => {
    props.setProcessing(true);

    apiAxios

      .get("/admin/secure/admins", {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          props.setProcessing(false);

          setReady(true);

          setAdmins(axiosResponse.data.admins);
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          setReady(true);

          if (axiosError.response?.data?.errorCode === "adminsNotFound") return;

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <PageTitle>Admins</PageTitle>

      <Subnavigation>
        <StyledLink to="/admins/create">Create</StyledLink>
      </Subnavigation>

      <Content>
        {ready && (
          <>
            {admins ? (
              <Admins>
                {admins.map((admin, index) => (
                  <Admin key={index} to={`/admins/${admin.id}`}>
                    <AdminTileComponent index={index} admin={admin} />
                  </Admin>
                ))}
              </Admins>
            ) : (
              <NoAdminsMessage>There are no Admins.</NoAdminsMessage>
            )}
          </>
        )}
      </Content>

      <Actions>
        <ButtonControl onClick={onRefresh} disabled={props.processing}>
          Refresh
        </ButtonControl>
      </Actions>
    </Container>
  );
};

export default Index;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto auto 1fr auto;

  height: 100%;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  border-bottom: solid 1px #6b6b6b;
`;

const Subnavigation = styled.div`
  display: flex;

  align-items: center;

  gap: 0.5rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.3rem;

  text-decoration: none;

  padding: 0.3rem;

  &:hover {
    background-color: #6b6b6b;

    color: #ffffff;
  }
`;

const Content = styled.div``;

const NoAdminsMessage = styled.div``;

const Admins = styled.div`
  position: relative;
  display: grid;

  grid-gap: 0.5rem;

  grid-template-columns: repeat(4, 0.25fr);
`;

const Admin = styled(Link)``;

const Actions = styled.div`
  display: flex;

  justify-content: flex-end;
`;
