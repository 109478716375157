export const formatBytes = (a: number, b: number = 2) => {
  if (0 === a) return "0 Bytes";

  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));

  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",

  currency: "USD",

  minimumFractionDigits: 0,

  maximumFractionDigits: 0,
});
