import { useState, useEffect } from "react";

import styled from "styled-components";

import ButtonControl from "../../controls/Button";

import TextInputControl from "../../controls/TextInput";

import CheckboxControl from "../../controls/Checkbox";

import apiAxios from "../../../../helpers/apiAxios";

import { AxiosError, AxiosResponse } from "axios";

import { RouteComponentProps } from "react-router";

import { IParameters } from "../../../../helpers/interfaces";

import { ICurrentAdmin, IAdmin } from "../../../../helpers/interfaces";

import { Link } from "react-router-dom";

interface IMatchParams {
  adminId: string;
}

interface IProps extends RouteComponentProps<IMatchParams> {
  processing: boolean;

  setProcessing: (value: boolean) => void;

  parameters: IParameters;

  sessionId: string;

  currentAdmin: ICurrentAdmin;

  onSessionInvalid: () => void;
}

const Update = (props: IProps) => {
  const [name, setName] = useState<string | null>(null);

  const [emailAddress, setEmailAddress] = useState<string | null>(null);

  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | null>(null);

  const { setProcessing, match, history, onSessionInvalid, sessionId } = props;

  useEffect(() => {
    setProcessing(true);

    apiAxios

      .get(`/admin/secure/admins/${match.params.adminId}`, {
        headers: {
          sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          setProcessing(false);

          const admin = axiosResponse.data.admin as IAdmin;

          setName(admin.name);

          setEmailAddress(admin.emailAddress);

          setIsSuperAdmin(admin.isSuperAdmin === 1);
        },

        (axiosError: AxiosError) => {
          setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  }, []);

  const onReset = () => {
    if (
      !window.confirm(
        `You are about to discard any changes made and reset the page.\n\nProceed?`
      )
    )
      return;

    props.setProcessing(true);

    apiAxios

      .get(`/admin/secure/admins/${props.match.params.adminId}`, {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        (axiosResponse: AxiosResponse) => {
          props.setProcessing(false);

          const admin = axiosResponse.data.admin as IAdmin;

          setName(admin.name);

          setEmailAddress(admin.emailAddress);

          setIsSuperAdmin(admin.isSuperAdmin === 1);
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  const onDelete = () => {
    const confirmed = window.prompt(
      `You are about to delete this Admin.\n\nIf you're sure, type DELETE and click OK to proceed.`
    );

    if (!confirmed) return;

    if (confirmed !== "DELETE")
      return alert(
        "Checkpoint failed; operation aborted.\n\nYou must enter DELETE (in capitals) to confirm the operation.\n\nPlease try again."
      );

    props.setProcessing(true);

    apiAxios

      .delete(`/admin/secure/admins/${props.match.params.adminId}`, {
        headers: {
          sessionId: props.sessionId,
        },
      })

      .then(
        () => {
          props.setProcessing(false);

          props.history.push(`/admins/deleted`);
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  const onSave = () => {
    if (!name || !emailAddress)
      return alert(
        "Incomplete form; completed all required form fields and try again."
      );

    if (!window.confirm(`You are about to update this Admin.\n\nProceed?`))
      return;

    const formData = new FormData();

    formData.append("name", name);

    formData.append("emailAddress", emailAddress);

    formData.append("isSuperAdmin", isSuperAdmin ? "1" : "0");

    props.setProcessing(true);

    apiAxios

      .put(
        `/admin/secure/admins/${props.match.params.adminId}`,

        formData,

        {
          headers: {
            sessionId: props.sessionId,
          },
        }
      )

      .then(
        () => {
          props.setProcessing(false);

          props.history.push(`/admins/${props.match.params.adminId}/updated`);
        },

        (axiosError: AxiosError) => {
          props.setProcessing(false);

          if (axiosError.response?.data?.errorCode === "unauthorized")
            return props.history.push("/unauthorized");

          if (axiosError.response?.data?.errorCode === "accessDenied")
            return props.onSessionInvalid();

          if (axiosError.response?.data.errorCode)
            return alert(
              `Unable to process your request.\n\n${axiosError.response.data.errorCode}.\n\nPlease try again.`
            );

          alert(
            `Unable to process your request.\n\n${axiosError.message}.\n\nPlease try again.`
          );
        }
      );
  };

  return (
    <Container>
      <PageTitle>Update Admin</PageTitle>

      <Subnavigation>
        <StyledLink
          to={`/admins/${props.match.params.adminId}/change-password`}
        >
          Change Password
        </StyledLink>
      </Subnavigation>

      <Content>
        <Sections>
          <Section>
            <SectionTitle>Details</SectionTitle>

            <SectionDescription>
              Specify the Admin details by completing the form below.
            </SectionDescription>

            <SectionContent>
              <DetailsForm>
                <DetailsFormFields>
                  <DetailsFormField>
                    <DetailsFormFieldTitle>Name</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="text"
                        maxLength={50}
                        placeholder="Name"
                        value={name || ""}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>Email Address</DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <TextInputControl
                        disabled={props.processing}
                        required
                        type="email"
                        maxLength={50}
                        placeholder="Email Address"
                        value={emailAddress || ""}
                        onChange={(e) => setEmailAddress(e.target.value)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>

                  <DetailsFormField>
                    <DetailsFormFieldTitle>
                      Security Level
                    </DetailsFormFieldTitle>

                    <DetailsFormFieldInput>
                      <CheckboxControl
                        disabled={props.processing}
                        required
                        labelText="This Admin is a Super Admin"
                        checked={isSuperAdmin || false}
                        onChange={(e) => setIsSuperAdmin(e.target.checked)}
                      />
                    </DetailsFormFieldInput>
                  </DetailsFormField>
                </DetailsFormFields>
              </DetailsForm>
            </SectionContent>
          </Section>
        </Sections>
      </Content>

      <Actions>
        <ButtonControl onClick={onDelete} disabled={props.processing}>
          Delete
        </ButtonControl>

        <ButtonControl onClick={onReset} disabled={props.processing}>
          Reset
        </ButtonControl>

        <ButtonControl
          onClick={onSave}
          disabled={props.processing || !name || !emailAddress}
        >
          Save
        </ButtonControl>
      </Actions>
    </Container>
  );
};

export default Update;

const Container = styled.div`
  display: grid;

  grid-gap: 0.5rem;

  grid-template-rows: auto auto 1fr auto;

  height: 100%;
`;

const PageTitle = styled.div`
  font-size: 3rem;

  text-align: right;

  padding-bottom: 0.5rem;

  border-bottom: solid 1px #6b6b6b;

  margin-bottom: 0.5rem;
`;

const Subnavigation = styled.div`
  display: flex;

  align-items: center;

  gap: 0.5rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.3rem;

  text-decoration: none;

  padding: 0.3rem;

  &:hover {
    background-color: #6b6b6b;

    color: #ffffff;
  }
`;

const Content = styled.div``;

const Sections = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const Section = styled.div`
  border: solid 1px #f6f6f6;

  padding: 0.5rem;

  display: grid;

  grid-template-rows: auto auto 1fr;

  grid-gap: 0.5rem;

  height: 100%;
`;

const SectionTitle = styled.div`
  font-size: 1.5rem;

  font-weight: 600;

  text-transform: uppercase;
`;

const SectionDescription = styled.div`
  font-size: 1.3rem;
`;

const SectionContent = styled.div`
  background-color: #f6f6f6;

  padding: 0.5rem;

  height: 100%;

  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsForm = styled.div``;

const DetailsFormFields = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormField = styled.div`
  display: grid;

  grid-gap: 0.5rem;
`;

const DetailsFormFieldTitle = styled.div``;

const DetailsFormFieldInput = styled.div`
  display: grid;
`;

const Actions = styled.div`
  display: flex;

  justify-content: flex-end;

  gap: 0.5rem;
`;
